<template>
  <div id="app">

    <h1>Vorise</h1>
    <h1>沃来思技术</h1>
    <footer-info/>
  </div>
</template>

<script>
import footerInfo from './components/FooterInfo.vue'

export default {
  name: 'App',
  components: {
    footerInfo
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
